import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../config';
import { useSelector } from "react-redux";
import { Footer } from './Footer';
import { Header } from './Header'
import { Link } from 'react-router-dom';

import img1 from './images/tudorarchshapes/arc2.png'
import img2 from './images/tudorarchshapes/arc3.png'
import img3 from './images/tudorarchshapes/arc4.png'
import img4 from './images/tudorarchshapes/arc5.png'
import img5 from './images/tudorarchshapes/arc6.png'

export const Project = () => {


  useEffect(() => {
    getdata();
  }, [])


  const [product, setProduct] = useState();
  const [visibleMenu, setVisibleMenu] = useState(); // <- HERE

  const getdata = async () => {

    const { data } = await axiosInstance.get("/api/product");
    console.log("projectPageData", data);
    setProduct(data);
    setVisibleMenu(data);


  }
  const filterItem = (catitem) => {

    const updatedItems = product.filter((item) => item.ProductCategory === catitem);
    console.log("updatedItems", updatedItems)
    setVisibleMenu(updatedItems);
    // alert(catitem)

  }



  const myArray = [img1, img2, img3, img4, img5,img1,img2,img3,img4,img5,img1,img2,img3,img4,img5,img2,img3,img4,img5,img1,img2,img3,img4,img5];


  return (
    <div>


      <Header />


      <section className="project row justify-content-between align-items-center container mx-auto" style={{ height: '80vh' }}>
        <div className="col-lg-6 text-dark text-start">
          <h1 style={{ fontSize: '4rem' }}><b>Projects</b></h1>
          <p>
          Every project is unique, but all are united by our pursuit of creating inspiring architecture. We invite you to explore our extensive experience across a wide range of sectors.
          </p>
        </div>
        <div className="col-lg-3 text-start">
          <b>Filter</b>
          <a href="#down"><p style={{ cursor: 'pointer' }} onClick={() => setVisibleMenu(product)} ><a>All</a></p></a>
          <a href="#down"><p style={{ cursor: 'pointer' }} onClick={() => filterItem('Interior Design')}><a>Interior Design</a></p></a>
          <a href="#down"><p style={{ cursor: 'pointer' }} onClick={() => filterItem('Alivation')}><a>Alivation</a></p></a>
          <a href="#down"><p style={{ cursor: 'pointer' }} onClick={() => filterItem("Courtyards Design")}><a>Courtyards Design</a></p></a>
        </div>
      </section>


      <div className="container-fluid" >

        <div className="row py-5" id="down">
          {
            visibleMenu && visibleMenu.map((c, i) => (
              <>
                <div className="col-lg-4 py-4">
                  <Link to={`/singleproject/${c.ProductName}`}>
                    <div className="quaitybg3" style={{  backgroundImage: "url(" + `https://tudorarchdesign.com/uploadFiles/${c.ProductImage}` + ")"}}>
                      <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                        <h4 className="text-white">{c.ProductName}</h4>
                        <h2 className="text-white text-muted"><b>{c.ProductHeading}</b></h2>
                        {/* <a href="#"><i class="fa-solid fa-up-long text-white" style={{ fontSize: '2rem' }}></i></a> */}
                      </div>
                      <div className="innerquality3 d-flex justify-content-between text-start flex-column p-5" >
                        <div>
                          <h4 className="text-white">{c.ProductName}</h4>
                          <h2 className="text-white text-muted"><b>{c.ProductHeading}</b></h2>
                          <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                        </div>
                        <div className="text-end">

                          <img src={myArray[i]} className="ms-auto" width="100px" height="100px" alt="arrayarc" />
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </Link>
                </div>

              </>
            ))
          }
        </div>

      </div>


      <Footer />

    </div>
  )
}
