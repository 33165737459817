import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export const Slider_anime = ({ id, changedesign }) => {

    //   alert(changedesign)







    // const [id, setid] = useState("One");
    const [slider, slideropen] = useState("One");

    useEffect(() => {
        let items = document.querySelectorAll('.carousel-item')

        items.forEach((el) => {

            const minPerSlide = 1
            let next = el.nextElementSibling
            for (var i = 1; i < minPerSlide; i++) {
                if (!next) {
                    next = items[0]
                }
                let cloneChild = next.cloneNode(true)
                el.appendChild(cloneChild.children[0])
                next = next.nextElementSibling
            }
        })
    }, [])

    const sliderope = (mm) => {
        slideropen(mm)
        // alert(mm)
        let slider = document.querySelector('#carouselExampleInterval');
        let coladd = document.querySelectorAll('.ad_col');
        slider.classList.toggle('mini_slide');
        coladd.forEach(x => {
            x.classList.remove('col-md-4')
            x.classList.add('col-md-12')
            x.style.transition = "all 0.4s ease"

        });
    }








    return (
        <div>


            <section className="bg-dark d-lg-block d-none">
                <div class="container-fluid text-center px-0" id="carouselExampleInterval" style={{ height: '100vh', display: 'flex' }}>
                    <div class="row mx-auto my-auto justify-content-center">
                        <div id="recipeCarousel" class="carousel slide px-0" data-bs-ride="carousel">
                            <div class="carousel-inner" role="listbox">
                                <div className={id === "One" ? "carousel-item active" : "carousel-item "} name="a" onClick={() => { sliderope("One") }}  >
                                    <div class="col-md-12 ad_col">
                                        <div class="card">
                                            <div class="card-img">
                                                <img src={require("../images/21.jpeg")} class="img-fluid"  width="100%" />
                                            </div>
                                            <div class="card-img-overlay d-flex align-items-center justify-content-center text-white" style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
                                                <div>
                                                    <div className="inner_text_div  text-center">
                                                        <nav aria-label="breadcrumb">
                                                            <ol class="breadcrumb justify-content-center">
                                                                <li class=""><Link to="/">Home &nbsp;</Link></li>
                                                                <li class=" active"><a>| Back to home &nbsp;</a></li>
                                                            </ol>
                                                        </nav>
                                                        <h2>Home</h2>
                                                        <small>This is our Home Page and let us know about our project</small><br /><br />
                                                        <Link to="/" class="btn py-2 rounded px-5 btn-outline-light">View More &nbsp;&nbsp;<i class="bi bi-arrow-right" style={{ fontSize: '1rem' }}></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={id === "Two" ? "carousel-item active" : "carousel-item "} name="d" onClick={() => { sliderope("Two") }}>
                                    <div class="col-md-12 ad_col">
                                        <div class="card">
                                            <div class="card-img">
                                                <img src={require("../images/22.jpeg")} class="img-fluid"  width="100%"/>
                                            </div>
                                            <div class="card-img-overlay d-flex align-items-center justify-content-center text-white" style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
                                                <div>
                                                    <div className="inner_text_div  text-center">
                                                        <nav aria-label="breadcrumb">
                                                            <ol class="breadcrumb justify-content-center">
                                                                <li class=""><Link to="/">Home &nbsp;</Link></li>
                                                                <li class=" active"><a>| Back to home &nbsp;</a></li>
                                                            </ol>
                                                        </nav>
                                                        <h2> About </h2>
                                                        <small>This is our Home Page and let us know about our project</small><br /><br />
                                                        <Link to="/about" class="btn py-2 rounded px-5 btn-outline-light">View More &nbsp;&nbsp;<i class="bi bi-arrow-right" style={{ fontSize: '1rem' }}></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={id === "Three" ? "carousel-item active" : "carousel-item "} id="three" name="b" onClick={() => { sliderope("Three") }}>
                                    <div class="col-md-12 ad_col">
                                        <div class="card">
                                            <div class="card-img">
                                                <img src={require("../images/23.jpeg")} class="img-fluid" width="100%" />
                                            </div>
                                            <div class="card-img-overlay d-flex align-items-center justify-content-center text-white" style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
                                                <div>
                                                    <div className="inner_text_div  text-center">
                                                        <nav aria-label="breadcrumb">
                                                            <ol class="breadcrumb justify-content-center">
                                                                <li class=""><Link to="/">Home &nbsp;</Link></li>
                                                                <li class=" active"><a>| Back to home &nbsp;</a></li>
                                                            </ol>
                                                        </nav>
                                                        <h2> Contact </h2>
                                                        <small>This is our Home Page and let us know about our project</small><br /><br />
                                                        <Link to="/contact-us" class="btn py-2 rounded px-5 btn-outline-light">View More &nbsp;&nbsp;<i class="bi bi-arrow-right" style={{ fontSize: '1rem' }}></i></Link>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={id === "Four" ? "carousel-item active" : "carousel-item "} id="four" name="b" onClick={() => { sliderope("Four") }}>
                                    <div class="col-md-12 ad_col">
                                        <div class="card">
                                            <div class="card-img">
                                                <img  src={require("../images/24.jpeg")} class="img-fluid" width="100%" />
                                            </div>
                                            <div class="card-img-overlay d-flex align-items-center justify-content-center text-white" style={{ backgroundColor: 'rgba(0,0,0,0.6)' }}>
                                                <div>
                                                    <div className="inner_text_div  text-center">
                                                        <nav aria-label="breadcrumb">
                                                            <ol class="breadcrumb justify-content-center">
                                                                <li class=""><Link to="/">Home &nbsp;</Link></li>
                                                                <li class=" active"><a>| Back to home &nbsp;</a></li>
                                                            </ol>
                                                        </nav>
                                                        <h2> Services </h2>
                                                        <small>This is our Home Page and let us know about our project</small><br /><br />
                                                        <Link to="/service" class="btn py-2 rounded px-5 btn-outline-light">View More &nbsp;&nbsp;<i class="bi bi-arrow-right" style={{ fontSize: '1rem' }}></i></Link>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="carousel-item">
                                    <div class="col-md-12 ad_col">
                                        <div class="card">
                                            <div class="card-img">
                                                <img src="https://minaleandmann.com/wp-content/uploads/2018/04/1-New-Size-11.jpg" class="img-fluid" />
                                            </div>
                                            <div class="card-img-overlay d-flex align-items-center justify-content-center text-white" style={{backgroundColor:'rgba(0,0,0,0.6)'}}><h2><b>Slide 4</b></h2></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <div class="col-md-12 ad_col">
                                        <div class="card">
                                            <div class="card-img">
                                                <img src="https://minaleandmann.com/wp-content/uploads/2018/04/1-New-Size-11.jpg" class="img-fluid" />
                                            </div>
                                            <div class="card-img-overlay d-flex align-items-center justify-content-center text-white" style={{backgroundColor:'rgba(0,0,0,0.6)'}}><h2><b>Slide 5</b></h2></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item ">
                                    <div class="col-md-12 ad_col">
                                        <div class="card">
                                            <div class="card-img">
                                                <img src="https://minaleandmann.com/wp-content/uploads/2018/04/1-New-Size-11.jpg" class="img-fluid" />
                                            </div>
                                            <div class="card-img-overlay d-flex align-items-center justify-content-center text-white" style={{backgroundColor:'rgba(0,0,0,0.6)'}}><h2><b>Slide 6</b></h2></div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <a class="carousel-control-prev bg-transparent w-aut" href="#recipeCarousel" role="button" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            </a>
                            <a class="carousel-control-next bg-transparent w-aut" href="#recipeCarousel" role="button" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            </a>
                        </div>
                    </div>
                </div>

            </section>

            {/* -----mobile slider---- */}

            {
                !changedesign ? <>
                    <section className="mob_slider d-lg-none d-flex justify-content-center align-items-center" style={{ marginTop: '60px', height: '90vh', backgroundColor: 'rgb(27, 27, 30)' }}>
                        <OwlCarousel className='owl-theme' loop margin={10} nav>

                            <div class='item' style={{ width: '360px' }}>
                                <img src={require("../images/21.jpeg")} className='img-fluid  m-slide' />
                                <div class="carousel-caption p-5">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb justify-content-center">
                                            <li class=""><Link to="/">Home &nbsp;</Link></li>
                                            <li class=" active"><a>| Back to home &nbsp;</a></li>
                                        </ol>
                                    </nav>
                                    <h5>Home</h5>
                                    <p>Top representative placeholder content for the first slide.</p>
                                    <Link to="/" class="btn py-2 rounded px-5 btn-outline-light">View More &nbsp;&nbsp;<i class="bi bi-arrow-right" style={{ fontSize: '1rem' }}></i></Link>
                                </div>
                            </div>
                            <div class='item ' style={{ width: '360px' }}>
                                <img src={require("../images/22.jpeg")} className='img-fluid  m-slide' />
                                <div class="carousel-caption p-5">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb justify-content-center">
                                            <li class=""><Link to="/">About &nbsp;</Link></li>
                                            <li class=" active"><a>| Back to Home &nbsp;</a></li>
                                        </ol>
                                    </nav>
                                    <h5>About</h5>
                                    <p>Top representative placeholder content for the first slide.</p>
                                    <Link to="/" class="btn py-2 rounded px-5 btn-outline-light">View More &nbsp;&nbsp;<i class="bi bi-arrow-right" style={{ fontSize: '1rem' }}></i></Link>
                                </div>
                            </div>
                            <div class='item ' style={{ width: '360px' }}>
                                <img src={require("../images/23.jpeg")} className='img-fluid  m-slide' />
                                <div class="carousel-caption p-5">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb justify-content-center">
                                            <li class=""><Link to="/">Contact &nbsp;</Link></li>
                                            <li class=" active"><a>| Back to Home &nbsp;</a></li>
                                        </ol>
                                    </nav>
                                    <h5>Contact</h5>
                                    <p>Top representative placeholder content for the first slide.</p>
                                    <Link to="/contact" class="btn py-2 rounded px-5 btn-outline-light">View More &nbsp;&nbsp;<i class="bi bi-arrow-right" style={{ fontSize: '1rem' }}></i></Link>
                                </div>
                            </div>
                            <div class='item ' style={{ width: '360px' }}>
                                <img src={require("../images/24.jpeg")} className='img-fluid  m-slide' />
                                <div class="carousel-caption p-5">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb justify-content-center">
                                            <li class=""><Link to="/">Services &nbsp;</Link></li>
                                            <li class=" active"><a>| Back to home &nbsp;</a></li>
                                        </ol>
                                    </nav>
                                    <h5>Services</h5>
                                    <p>Top representative placeholder content for the first slide.</p>
                                    <Link to="/" class="btn py-2 rounded px-5 btn-outline-light">View More &nbsp;&nbsp;<i class="bi bi-arrow-right" style={{ fontSize: '1rem' }}></i></Link>
                                </div>
                            </div>
                    
                           


                        </OwlCarousel>;
                    </section>

                </> : <>
                    <section className="mob_slider d-lg-none d-flex justify-content-center align-items-center" style={{ marginTop: '60px', height: '90vh', backgroundColor: 'rgb(27, 27, 30)' }}>
                        <OwlCarousel className='owl-theme' loop margin={10} nav>

                            <div class='item position-relative' style={{ width: '360px' }}>
                                <img src={require('../images/6.jpeg')} className='img-fluid changeclass' />
                                <div class="carousel-caption p-5">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb justify-content-center">
                                            <li class=""><Link to="/">Home &nbsp;</Link></li>
                                            <li class=" active"><a>| Back to home &nbsp;</a></li>
                                        </ol>
                                    </nav>
                                    <h5>Home</h5>
                                    <p>Top representative placeholder content for the first slide.</p>
                                    <Link to="/" class="btn py-2 rounded px-5 btn-outline-light">View More &nbsp;&nbsp;<i class="bi bi-arrow-right" style={{ fontSize: '1rem' }}></i></Link>
                                </div>
                            </div>
                            <div class='item ' style={{ width: '360px' }}>
                                <img src={require('../images/7.jpeg')} className='img-fluid changeclass' />
                                <div class="carousel-caption p-5">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb justify-content-center">
                                            <li class=""><Link to="/">About &nbsp;</Link></li>
                                            <li class=" active"><a>| Back to home &nbsp;</a></li>
                                        </ol>
                                    </nav>
                                    <h5>About</h5>
                                    <p>Top representative placeholder content for the first slide.</p>
                                    <Link to="/" class="btn py-2 rounded px-5 btn-outline-light">View More &nbsp;&nbsp;<i class="bi bi-arrow-right" style={{ fontSize: '1rem' }}></i></Link>
                                </div>
                            </div>
                            <div class='item ' style={{ width: '360px' }}>
                                <img src={require('../images/8.jpeg')} className='img-fluid changeclass' />
                                <div class="carousel-caption p-5">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb justify-content-center">
                                            <li class=""><Link to="/">Contact &nbsp;</Link></li>
                                            <li class=" active"><a>| Back to home &nbsp;</a></li>
                                        </ol>
                                    </nav>
                                    <h5>Contact</h5>
                                    <p>Top representative placeholder content for the first slide.</p>
                                    <Link to="/" class="btn py-2 rounded px-5 btn-outline-light">View More &nbsp;&nbsp;<i class="bi bi-arrow-right" style={{ fontSize: '1rem' }}></i></Link>
                                </div>
                            </div>
                            <div class='item ' style={{ width: '360px' }}>
                                <img src={require('../images/10.jpeg')} className='img-fluid changeclass' />
                                <div class="carousel-caption p-5">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb justify-content-center">
                                            <li class=""><Link to="/">Services &nbsp;</Link></li>
                                            <li class=" active"><a>| Back to home &nbsp;</a></li>
                                        </ol>
                                    </nav>
                                    <h5>Services</h5>
                                    <p>Top representative placeholder content for the first slide.</p>
                                    <Link to="/" class="btn py-2 rounded px-5 btn-outline-light">View More &nbsp;&nbsp;<i class="bi bi-arrow-right" style={{ fontSize: '1rem' }}></i></Link>
                                </div>
                            </div>
                            

                        </OwlCarousel>;
                    </section>

                </>

            }


            {/* -----mobile slider---- */}













        </div>
    )
}
