import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../config';
import { Header } from './Header'
import { Footer } from './Footer'

export const Product = () => {


    

    const location = useLocation();

    const { category,image } = location?.state

    const [productdata, setProductData] = useState();
    const [ref, setref] = useState(true);

    console.log("productdata product id", productdata)

    const { id } = useParams();
    console.log("myid", id)

    




    // window.location.reload(false)






    useEffect(() => {
         window.scrollTo(0, 0)
         
        getdata();
    }, [!ref])



    // get data First---
    const getdata = async () => {
        const { data } = await axiosInstance.get(`/api/product`)
        console.log(data)
        setProductData(data);
    }

    // const newdata = productdata.filter((item)=>item.ProductCategory === "Linen fabrics")
    // console.log("newdata",newdata)


    return (
        <div>

            <Header />

            <section className="product_banner d-flex justify-content-center align-items-center text-white" style={{backgroundSize:'cover',backgroundPosition:'center',backgroundImage: `url(${require(`./images/${image}`)})`}} >
                <h1><b>{category}</b></h1>
            </section>



            {/* <section className=" py-5" >
                <div className="container rounded bg-white p-5" style={{ boxShadow: ' rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                    <div className="row ">

                    
                        
                        <div className="col-lg-4">
                            <div class="card vendor_card bg-light border-0">
                                <div className="ven_bg p-2">
                                    {
                                        productdata?.ProductImage && <img src={require(`../../../api/uploadFiles/${productdata?.ProductImage}`)} class="card-img-top" alt="..." />
                                    }
                                    <div class="card-body ">
                                        <div className="d-flex text-muted justify-content-between">
                                            <h6 class="card-title">{productdata?.ProductName}</h6>
                                            <i class="fa-solid fa-star text-warning" style={{ fontSize: '0.7rem' }}>5.0</i>
                                        </div>
                                        <div className="d-flex text-muted  justify-content-between">
                                            <span classname="text-muted"><i class="fa-solid fa-location-dot"></i>{productdata?.ProductHeading}</span>
                                            <span>{productdata?.ProductStatus}</span>
                                        </div>
                                        
                                    </div>
                                    <div className="topbar p-2 d-flex justify-content-between">
                                        <span class="badge  px-3 py-2" style={{ backgroundColor: 'rgba(231, 46, 119)' }}><i class="fa-solid fa-fire" ></i> &nbsp;Popular</span>

                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    


                    </div>
                </div>
            </section> */}

            <section className="gallery" >
                <div class="container photos_container" style={{ minHeight: '70vh' }}>
                    <div class="gallery d-flex flex-wrap align-items-center justify-content-center">
                        {
                            productdata && productdata.filter((item) => item.ProductCategory === category).map((item) => (

                                <>
                                    <div className="col-lg-3 col-9">
                                        <a href={`https://www.jdmorgaan.com/uploadFiles/${item?.ProductImage}`} data-lightbox="models" className="rounded" data-title="Caption1">
                                            <div class="card rounded">
                                                <img src={`https://www.jdmorgaan.com/uploadFiles/${item?.ProductImage}`} className="img-fluid" style={{ width: 'auto', borderRadius: '0' }} />

                                                <div class="card-body w-100 row justify-content-between align-items-center text-dark">

                                                    <em>
                                                        {item.ProductName}
                                                    </em>
                                                    <h6 class="card-text">{item.ProductDescription}</h6>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                   
                                </>

                            ))
                        }


                    </div>
                </div>
            </section>



            <section className="bottom_banner text-white py-5" style={{ backgroundColor: '#010100' }}>
                <div className="text-center">
                    <div className="logo">
                        <h1 style={{ color: '#010100' }}>JD Morgaan<span style={{ color: '#010100', fontSize: '2.3rem', opacity: '0.3' }}>.</span> </h1>
                        {/* <img className='bg-white foteer_img' src={bg}  alt="" /> */}
                    </div>
                    <div>
                        <h3><b>EXPERIENCE THE CHARMS OF CLASSY AND AFFORDABLE FABRICS</b></h3>
                        <p>
                            Start your journey into the world of vibrant yet stylish <br /> and high quality Fabrics.
                        </p>
                        <a href="#" className="btn btn-dark text-white">know more</a>
                    </div>
                </div>
            </section>


            <Footer />

        </div>
    )
}
