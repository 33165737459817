import React, { useRef } from 'react'
import emailjs from 'emailjs-com';
import { Footer } from './Footer'
import { Header } from './Header'
import { Slider_anime } from './Slider_anime';

export const Conactus = () => {
    const form = useRef();

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_miu257w', 'template_15511h9', form.current, '2iISU3dq2OeUxT4El')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }

    return (
        <div>


            {/* <section className="contact_banner d-flex justify-content-start align-items-center " style={{ height: '100vh' }}>
                <div className="text-center text-white px-5">
                    <h1 style={{ fontSize: '4rem' }}><b>Our studio is the heart <br /> of our creative process</b></h1>
                </div>
            </section> */}
            <Header />
            <Slider_anime id="Three"/>





            <section className="form py-5" style={{backgroundColor: '#1B1B1E' }}>
                <div className="container">
                    <div className="row" >
                        <div className="col-lg-12 pt-5 border" style={{ backgroundColor: '#fff', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                            <div>
                                <form ref={form} onSubmit={sendEmail}>
                                    <div class="row py-2">
                                        <div class="col">
                                            <label for="exampleFormControlInput1" class="form-label">First Name</label>
                                            <input class="form-control" type="text" placeholder="First Name" name="f_name" id="f_name" aria-label="default input example" />
                                        </div>
                                        <div class="col">
                                            <label for="exampleFormControlInput1" class="form-label">Last Name</label>
                                            <input class="form-control" type="text" placeholder="Last Name" name="l_name" id="l_name" aria-label="default input example" />
                                        </div>
                                    </div>
                                    <div class="row py-2">
                                        <div class="col">
                                            <label for="exampleFormControlInput1" class="form-label">Phone Number</label>
                                            <input class="form-control" type="text" placeholder="Phone Number" name="number" id="number" aria-label="default input example" />
                                        </div>
                                        <div class="col">
                                            <label for="exampleFormControlInput1" class="form-label">Email</label>
                                            <input class="form-control" type="text" placeholder="E-mail" name="email" id="email" aria-label="default input example" />
                                        </div>
                                    </div>
                                    <div class="form-floating">
                                        {/* <label for="exampleFormControlInput1" class="form-label">Message</label> */}
                                        <textarea class="form-control" placeholder="Leave a Message here" style={{ height: '150px' }} id="floatingTextarea"></textarea>
                                        <label for="floatingTextarea" name="message" id="message">Type Your Message Here</label>
                                    </div>
                                    <div class="text-lg-start text-center py-3 ">
                                        <input type="submit" value="Send" class="btn btn-dark text-white px-5 py-2 col-12" />
                                    </div>
                                </form>
                            </div>
                        </div>
                     
                    </div>
                </div>
            </section>


            <Footer />


        </div >
    )
}
