import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../config';
import { useSelector } from "react-redux";
import { Footer } from './Footer';
import { Header } from './Header'
import { Link } from 'react-router-dom';
import img1 from './images/tudorarchshapes/arc2.png'
import img2 from './images/tudorarchshapes/arc3.png'
import img3 from './images/tudorarchshapes/arc4.png'
import img4 from './images/tudorarchshapes/arc5.png'
import img5 from './images/tudorarchshapes/arc6.png'
import {Slider_anime} from "./Slider_anime"

export const Service = () => {

    useEffect(() => {
        getdata();
    }, [])


    // const [demo, setDemo] = useState(false);
    const [service, setService] = useState();
    const [visibleMenu, setVisibleMenu] = useState(); //<- HERE
    console.log("servicedata", service)

    const getdata = async () => {

        const { data } = await axiosInstance.get("/api/service/");
        console.log("projectPageData", data);
        setService(data);
        setVisibleMenu(data)

    }

    const filterItem = (catitem) => {

        const updatedItems = service.filter((item) => item.ServiceCategory === catitem);
        setVisibleMenu(updatedItems);
        console.log("updatedItems", updatedItems)


    }

    const myArray = [img1, img2, img3,img4,img5];
    return (
        <div>


            <Header />
            <Slider_anime id="Four"/>

            <section className="service_banner d-flex justify-content-start align-items-center " style={{ height: '100vh' }}>
                <div className="text-start text-white px-5">
                    <h1 style={{ fontSize: '4rem' }}><b>We serve you with the <br /> best craftsmanship of Architecture</b></h1>
                </div>
            </section>

            <section className="project row justify-content-between align-items-center container mx-auto" style={{ height: '80vh' }}>
                <div className="col-lg-6 text-dark text-start">
                    <h1 style={{ fontSize: '4rem' }}><b>Services</b></h1>
                    <p>
                    Although each project is distinct, they are all connected by our desire to design inspiring architecture.We cordially encourage you to learn more about our broad variety of expertise
                    </p>
                </div>
                <div className="col-lg-3 text-start">
                    <b>Filter</b>
                    <a href="#down"><p style={{ cursor: 'pointer' }} onClick={() => setVisibleMenu(service)} ><a>All</a></p></a>
                    <a href="#down"><p style={{ cursor: 'pointer' }} onClick={() => filterItem("Architecture")}><a>Architecture</a></p></a>
                    <a href="#down"><p style={{ cursor: 'pointer' }} onClick={() => filterItem("Landscape")}><a>Landscape</a></p></a>
                    <a href="#down"><p style={{ cursor: 'pointer' }} onClick={() => filterItem("Vastu Consultancy")}><a>Vastu Consultancy</a></p></a>
                    <a href="#down"><p style={{ cursor: 'pointer' }} onClick={() => filterItem("Modular Kitchen")}><a>Modular Kitchen</a></p></a>
                </div>
            </section>



            <div className="container-fluid">

                <div className="row py-5" id="down">
                    {
                        visibleMenu && visibleMenu.map((c,i) => (
                            <>
                                <div className="col-lg-4 py-4">
                                    <Link to={`/singleservice/${c.ServiceName}`}>
                                        <div className="quaitybg3" style={{  backgroundImage: "url(" + `https://tudorarchdesign.com/uploadFiles/${c.ServiceImage}` + ")"}}>
                                            <div className="text-end p-5 innerqualityup" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
                                                <h4 className="text-white">{c.ServiceCategory}</h4>
                                                <h2 className="text-white text-muted"><b>{c.ServiceHeading}</b></h2>
                                            </div>
                                            <div className="innerquality3 d-flex justify-content-between flex-column text-start flex-column p-5" >
                                                <div>
                                                    <h4 className="text-white">{c.ServiceName}</h4>
                                                    <h2 className="text-white text-muted"><b>{c.ServiceHeading}</b></h2>
                                                    <a href="#"><i class="fas fa-long-arrow-alt-right text-white" style={{ fontSize: '2rem' }}></i></a>
                                                </div>
                                                {/* <div className="text-end">
                                                    
                                                    <img src={myArray[i]}  className="ms-auto" width="100px" height="100px" alt="arrayarc" />
                                                </div> */}
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            </>
                        ))

                    }
                </div>

            </div>

            <div class="cta1 py-5 container-fluid" >
                <div class="text-center text-white row justify-content-center align-items-center">
                    <div className="col-lg-6">
                        <h4>Call now and get additional <b>10% off</b>.</h4>
                    </div>
                </div>
                <div class="text-center d-flex row col-lg-4 mx-auto justify-content-around">
                    <div class="col-lg-6 col-6 px-1">
                        <Link to="/contact-us" class="btn btn-dark text-white"><i class="fas fa-rocket" ></i>&nbsp; Contact Us </Link><br />
                    </div>
                    <div class="col-lg-6 col-6 px-1">
                        <a href="tel:8328029263 " class="btn" style={{ backgroundColor: '#fff', color: '#222' }}><i class="fas fa-phone-alt" style={{ color: '#fff' }}></i>&nbsp; +91-8328029263</a>
                    </div>
                </div>
            </div>



            <Footer />

        </div>
    )
}
