import React, { useState } from 'react'
import { Footer } from './Footer'
import { Header } from './Header'
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../config';
import { useEffect } from 'react';
import parse from 'html-react-parser';



export const Singleproject = () => {

    useEffect(() => {
        getdata();
    }, [])

    const [product, setProduct] = useState();
    const { productname } = useParams();

    const getdata = async () => {

        const { data } = await axiosInstance.get(`/api/product/singleproject/${productname}`);
        console.log("productSingle", data);
        setProduct(data);

    }
    return (
        <div>


            <Header />

            {/* <section className="product_banner d-flex justify-content-start align-items-center " style={{backgroundImage: `url(${require(`../../../api/uploadFiles/${product?.productImage}`)})`}}> */}
            <section className="product_banner d-flex justify-content-start align-items-center " style={{  backgroundImage: "url(" + `https://tudorarchdesign.com/uploadFiles/${product?.ProductImage}` + ")"}}>
                <div className="text-center text-white px-5">
                    <h1 style={{ fontSize: '4rem' }}><b>{product?.ProductName}</b></h1>
                </div>
            </section>


            <div className="container-fluid">
                <div className="row p-5">
                    <div className="col-lg-6">
                        <div className="text-start">
                            <div className="py-2">
                                <h5 className="text-muted"> Client</h5>
                                <h6>{product?.ProductClient}</h6>
                            </div>
                            <div className="py-2">
                                <h5 className="text-muted"> Location</h5>
                                <h6>{product?.ProductLocation}</h6>
                            </div>
                            <div className="py-2">
                                <h5 className="text-muted"> Value</h5>
                                <h6>{product?.ProductPrice}</h6>
                            </div>
                            <div className="py-2">
                                <h5 className="text-muted"> Size</h5>
                                <h6>{product?.ProductSize}</h6>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <div className="text-start">
                            {/* {parse(product?.ProductDescription)} */}
                            {parse(`${product?.ProductDescription}`)}

                        </div>
                    </div>
                </div>
            </div>


            <div className="container">
               <div className="row">
               <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src={require('./images/interior4.jpg')} class="d-block w-100 img-fluid" style={{height:'70vh'}} alt="..."/>
                        </div>
                        <div class="carousel-item">
                            <img src={require('./images/interior2.jpg')} class="d-block w-100 img-fluid" style={{height:'70vh'}} alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src={require('./images/interior3.jpg')} class="d-block w-100 img-fluid" style={{height:'70vh'}} alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
               </div>
            </div>

            <Footer />



        </div>
    )
}
