import React from 'react'
import { useRef } from 'react'
// import bgfooter from "../images/logo.png"
// import bg from "../images/client/logohori.jpg"
import { Link } from 'react-router-dom'


export const Footer = () => {
    const newref = useRef();

    return (
        <div>


            {/* Footer=== */}
            {/* footer section */}
            <div className="footer" >
                <div className="container mt-5 text-center text-md-start text-white">
                    <div className="row justify-content-center align-items-center">
                        <div class="col-md-6 col-12 col-xl-3 mx-auto mb-4">

                            <div className="logobottom">
                                {/* <h1 style={{ color: '#010100' }}>Todor<span style={{ color: '#010100', fontSize: '2.3rem', opacity: '0.3' }}>.</span> </h1> */}
                                <a href="/">
                                <img className='heade_logo mx-0 ' style={{display:'unset'}} src={require('./images/weblogo.png')}  height="100px" alt="" />

                                </a>
                            </div>
{/* 
                            <hr
                                class="mb-4 mt-0 d-inline-block mx-auto"
                                style={{ width: "60px", background: "#010100", height: "2px" }}
                            /> */}
                            <p>
                                Tech Interio is committed to providing constant value to our customers by
                                providing best Quality Architecture & Designs to your spaces.
                            </p>
                            {/* <h6 class="text-uppercase fw-bold">Connect</h6>
                            <hr
                                class="mb-4 mt-0 d-inline-block mx-auto"
                                style={{ width: "60px", background: "#7c4dff", height: "2px" }}
                            />
                            <div className='d-flex justify-content-center '>
                                <a href="" class="mx-1 icons">
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                                <a href="" class="mx-1 icons">
                                    <i class="fab fa-twitter"></i>
                                </a>
                                <a href="" class="mx-1 icons">
                                    <i class="fab fa-google"></i>
                                </a>
                                <a href="" class="mx-1 icons">
                                    <i class="fab fa-instagram"></i>
                                </a>
                                <a href="" class="mx-1 icons" >
                                    <i class="fab fa-linkedin"></i>
                                </a>
                                <a href="" class=" mx-1 icons" >
                                    <i class="fab fa-github" ></i>
                                </a>
                            </div> */}
                        </div>
                        {/* 
                        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                            <h6 class="text-uppercase fw-bold">Projects</h6>
                            <hr
                                class="mb-4 mt-0 d-inline-block mx-auto"
                                style={{ width: "60px", background: "#7c4dff", height: "2px" }}
                            />
                            <p>
                                <a href="#!" class="">Demo</a>
                            </p>
                            <p>
                                <a href="#!" class="">Demo</a>
                            </p>
                            <p>
                                <a href="#!" class="">Demo</a>

                            </p>
                            <p>
                                <a href="#!" class="">Demo</a>
                            </p>
                        </div>
                        <div className="col-lg-3">
                            <h6 class="text-uppercase fw-bold">Useful links</h6>
                            <hr
                                class="mb-4 mt-0 d-inline-block mx-auto"
                                style={{ width: "60px", background: "#7c4dff", height: "2px" }}
                            />
                            <p>
                                <a href="#!" class="">Your Account</a>
                            </p>
                            <p>
                                <a href="#!" class="">Become an Affiliate</a>
                            </p>
                            <p>
                                <a href="#!" class="">Shipping Rates</a>
                            </p>
                        </div> */}
                        <div className="col-lg-6 col-12">
                            <h6 class="text-uppercase fw-bold text-dark">Contact</h6>
                            <hr
                                class="mb-4 mt-0 d-inline-block mx-auto"
                                style={{ width: "60px", background: "#7c4dff", height: "3px" }}
                            />
                            <p><i class="fas fa-home mr-3">&nbsp;&nbsp;</i>Tech Interio, 62,63 Haridhara complex,1st floor, Anjurphata, Bhiwandi, Maharashtra 421302</p>
                            <p><i class="fas fa-envelope mr-3">&nbsp;&nbsp;</i>Contact@techinterio.com</p>
                            <p><i class="fas fa-phone mr-3">&nbsp;&nbsp;</i>+91-8328029263  </p>
                            {/* <Link to="/login" className='btn btn-sm  bg-dark rounded-pill d-none d-lg-block' style={{ fontSize: '1rem' }}> <span style={{ color: "#fff" }}>Admin Login</span></Link> */}

                            {/* <p><i class="fas fa-phone mr-3">&nbsp;&nbsp;</i>+91-8445226722 </p> */}

                        </div>
                    </div>

                </div>

                {/* <div className="container">
                    <div className="row">
                        <div className="col-lg-4 d-flex flex-column text-start">
                            <Link to="/saras-reengineered">SARAS The Re-engineer ed</Link>
                            <Link to="/cbse-affiliation-process">CBSE Affiliation Process</Link>
                        </div>
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4"></div>
                    </div>
                    </div> */}

                {/* <div class="text-center p-3">
                    © 2022 Copyright:
                    <a style={{ color: '#ec661f' }} href="digisidekick.com"> Digisidekick.com</a>
                </div> */}
            </div>
            <div id="whatsapp" class="whatsapp">
                <a href="https://wa.link/hqfmq0" target="_blank" title="Whatsapp" data-bs-toggle="tooltip" data-bs-placement="top">
                    <i class="fab fa-whatsapp"></i>
                </a>
            </div>
            <div className="text-center text-white py-3" style={{ backgroundColor: 'RGBA(1, 1, 0,0.9)' }}>
                   <a href="https://www.facebook.com/jdmorgaan/" className="text-white"> <i class="fa-brands fa-facebook me-5" style={{fontSize:'2rem'}}></i></a>
                   <a href="https://instagram.com/jdmorgaan_whaleslinen?igshid=YmMyMTA2M2Y= "  className="text-white"> <i class="fa-brands fa-instagram" style={{fontSize:'2rem'}}></i></a>
            </div>
            {/* Footer=== */}

        </div>
    )
}
